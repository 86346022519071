<template>
<div>
    <v-container>
        <v-row>
            <v-col cols="12">
                <s-toolbar :color="'#244093'" dark label="Reporte de Ventas CV" class="tran"></s-toolbar>
                <v-card>

                    <v-row justify="center" style="margin-top: 4px; margin-left: 10px; margin: auto">
                        <v-col cols="4" lg="4" md="4">
                            <s-select-definition :def="1131" v-model="objMounth" return-object label="Seleccione mes"></s-select-definition>
                        </v-col>
                        <v-col cols="4" lg="4" md="4">
                            <s-text label="Ingrese año" number v-model="txtYear"></s-text>
                        </v-col>
                        <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                            <v-btn rounded :color="'info'" small @click="runView()">Buscar</v-btn>
                            <v-btn rounded :color="'error'" small @click="downloadReport()">Descargar</v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
            <v-card color="primary" dark>
                <v-card-text v-if="messageProcessing.length == 0">
                    Por favor espere
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text--white pt-4">
                        Error al realizar busqueda <br />
                        {{ messageProcessing }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
    <v-col cols="12">
        <v-row>
            <v-col cols="12">
                <v-data-table :height="items.length > 0 ? '400px': 'auto'" :headers="headers" :items="items" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                </v-data-table>
            </v-col>
        </v-row>
    </v-col>

</div>
</template>

<script>
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import _sQryConfigurationDetailService from "@/services/QueryManager/QryConfigurationDetailService";
import * as XLSX from 'xlsx';

export default {
    props: {

    },
    components: {
        
    },
    data() {
        return {
            processing: false,
            messageProcessing: "",

            items: [],
            headers: [],
            report: {},

            objMounth: null,
            txtYear: this.$fun.getYear(),
        };
    },

    methods: {
        runView() {
            this.messageProcessing = ""
            this.report.QryNameProcedure = "";
            this.report.QryParamsArray = "Mounth, Year"
            this.report.QryParamsArrayValue = this.objMounth.DedDescription + "," + this.txtYear //this.DateBegin + "," + this.DateEnd + "," + this.number;
            this.report.QryNameProcedure = "ClcSalesCv_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(this.report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.items = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.items[0]).length; i++
                            ) {
                                this.headers.splice(i, i, {
                                    text: Object.keys(this.items[0])[i],
                                    value: Object.keys(this.items[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        exportExcel() {
            var obj = [{
                hola: 'hola',
                hola1: 'hola1'
            }, {
                hola: 'hola',
                hola1: 'hola1'
            }]

            const workSheet1 = XLSX.utils.json_to_sheet(obj);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet1, "resolucion");
            //buffer
            let buffer = XLSX.write(workBook, {
                bookType: "xlsx",
                type: "buffer"
            });
            //binary
            XLSX.write(workBook, {
                bookType: "xlsx",
                type: "binary"
            });
            //donwload
            XLSX.writeFile(workBook, "reporte_resolucion.xlsx");

        },

        downloadReport() {
           /*  this.exportExcel()
            return; */

            this.messageProcessing = ""
            this.report.QryNameProcedure = "";
            this.report.QryParamsArray = "Mounth, Year"
            this.report.QryParamsArrayValue = this.objMounth.DedDescription + "," + this.txtYear //this.DateBegin + "," + this.DateEnd + "," + this.number;
            this.report.QryNameProcedure = "ClcSalesCv_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(this.report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte de Ventas CV"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        }
    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
