<template>
<div>

    <v-container>

        <v-tabs v-model="currentItem">
            <v-tab href="#Dashboard" > Dashboard</v-tab>
            <v-tab href="#SalesCV" > Reporte de Ventas CV</v-tab>
            <v-tab href="#SalesRE"> Reporte de Ventas RE</v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentItem">
            <v-tab-item :value="'SalesCV'">
                <clc-salescv />
            </v-tab-item>

            <v-tab-item :value="'SalesRE'">
                <clc-salesre />
            </v-tab-item>

            <v-tab-item :value="'Dashboard'">
                <!-- <clc-sales-dashboard /> -->
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</div>
</template>

<script>
//Component
import ClcSalescv from "./ClcSalescv.vue";
import ClcSalesre from "./ClcSalesre.vue";
/* import ClcSalesDashboard from './ClcSalesDashboard.vue'; */



export default {
    components: {
        ClcSalescv,
        ClcSalesre,
       /*  ClcSalesDashboard */
    },
    data() {
        return {
            currentItem: "tab-Funciones",
            itemSales: 0,
        }

    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
